<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
    onsubmit="return false;"
  >
    <v-container fluid row justify-end>
      <v-card color="white">
        <v-card-title primary-title class="bg-clr">
          <v-btn fab flat icon class="nospace" @click.native="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <span class="headline">&nbsp;Driver's Listing</span>
        </v-card-title>
        <v-card-text class="content-border">
          <loading :active.sync="loading" />
          <div class="heading primary-clr pt-3">
            Driver's Name & Profile Picture
          </div>
          <v-layout wrap>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">First Name*</div>
              <div class="heading">
                <v-text-field
                  label="First Name*"
                  class="pt-0 currencyTitle"
                  :rules="[
                    rules.noWhiteSpace,
                    rules.required,
                    rules.onlyEnglish,
                  ]"
                  v-model="firstName"
                  single-line
                  required
                  maxlength="45"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Last Name*</div>
              <div class="heading">
                <v-text-field
                  label="Last Name*"
                  class="pt-0 currencyTitle"
                  :rules="[
                    rules.noWhiteSpace,
                    rules.required,
                    rules.onlyEnglish,
                  ]"
                  v-model="lastName"
                  single-line
                  required
                  maxlength="45"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Profile Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileUploadProfile"
                  id="fileUploadProfile"
                  accept="image/*"
                  @change="profilePicChange"
                />
              </div>
              <div v-if="profilePicURL" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="profilePicURL">
                  <button type="button" class="m-10">
                    View Profile Picture
                  </button>
                </a>
              </div>

              <!-- <input type="file" > -->
            </v-flex>
            <v-flex md3></v-flex>
            <v-flex xs12 md3 sm6 class="pr-4">
              <div class="subheading muted caption">Country*</div>
              <div class="heading">
                <v-text-field
                  readonly
                  label="Country"
                  class="pt-0 currencyTitle"
                  v-model="countrySortName"
                  :value="countrySortName"
                  single-line
                />
              </div>
            </v-flex>
            <v-flex md2 sm6>
              <div class="muted caption">Driver Credit Period*</div>
              <v-text-field
                label="Driver Credit Period*"
                v-model="invoiceDay"
                required
                type="number"
                single-line
                suffix="days"
                class="pt-0 currencyTitle"
                :rules="[rules.required]"
              />
            </v-flex>
            <br />

            <v-flex xs12 class="pr-4" sm6 md3 style="padding-left: 40px">
              <div class="subheading muted caption">Choose Driver Type*</div>
              <div class="heading">
                <v-autocomplete
                  :items="UserTypes"
                  v-model="UserType"
                  class="pt-0 currencyTitle"
                  label="Choose Driver Type*"
                  :rules="[rules.required]"
                  single-line
                  disabled
                  autocomplete="offfadsf"
                  required
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-if="countrySortName != 'PAK' && this.UserType == 1"
            >
              <div class="subheading muted caption">Choose Border Type*</div>
              <div class="heading">
                <v-autocomplete
                  :items="borderTypes"
                  v-model="borderType"
                  class="pt-0 currencyTitle"
                  label="Choose Border Type*"
                  :rules="[rules.required]"
                  single-line
                  autocomplete="offfadsf"
                  required
                ></v-autocomplete>
              </div>
            </v-flex>
          </v-layout>

          <div
            v-if="this.UserType == 1 && countrySortName == 'UAE'"
            class="heading primary-clr pt-3"
          >
            Bank Details
          </div>

          <v-layout wrap v-if="this.UserType == 1 && countrySortName == 'UAE'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">All Banks Name</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="bankInfo"
                  item-text="bankName"
                  item-value="bankName"
                  v-model="bankName"
                  label="All Banks Name"
                  class="pt-0 currencyTitle"
                  single-line
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 md4 pl-3>
              <div class="subheading muted caption">Account Holder Name</div>
              <v-text-field
                class="pt-0 currencyTitle"
                v-model="bankHolderName"
                value="Transporter"
                label="Account Holder Name"
                :rules="[rules.onlyEnglish]"
                single-line
              ></v-text-field
            ></v-flex>

            <!-- <v-flex xs12 md4>
              <div class="subheading muted caption">All Transporter</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="transporters"
                  v-model="transporterId"
                  label="All Transporter*"
                  multiple
                  @change="!mainError ? (mainError = null) : ''"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                ></v-autocomplete>
              </div>
            </v-flex> -->
          </v-layout>

          <div v-if="this.UserType == 1" class="heading primary-clr pt-3">
            Registering As
          </div>
          <v-layout wrap v-if="this.UserType == 1">
            <v-flex xs12 md4>
              <v-layout>
                <v-checkbox
                  v-model="type"
                  value="Transporter"
                  disabled
                  @change="transporterId = []"
                  label="Transporter"
                ></v-checkbox>
                <v-checkbox
                  v-model="type"
                  label="Owner driver"
                  value="Owner"
                ></v-checkbox>
              </v-layout>
            </v-flex>
            <v-flex xs12 md6> </v-flex>

            <v-flex xs12 md4 v-if="type.includes('Transporter')">
              <div class="subheading muted caption">Transporter</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  disabled
                  append-icon="lock"
                  :items="transporters"
                  v-model="transporterId"
                  label="All Transporter*"
                  multiple
                  single-line
                  class="pt-0 currencyTitle"
                ></v-autocomplete>
              </div>
            </v-flex>
          </v-layout>

          <hr class="divider-rule" />
          <div class="heading primary-clr pt-3">Contact & Address Details</div>
          <v-layout row wrap>
            <v-flex xs12 class="pr-4" sm6 md2>
              <div class="subheading muted caption">Country Code*</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  :close-on-content-click="true"
                  attach
                  :items="countryCodes"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  v-model="countryCode"
                  label="Country Code*"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                  required
                  autocomplete="offfadsf"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Primary Phone Number*</div>
              <div class="heading">
                <v-text-field
                  label="Primary Phone Number*"
                  v-model="phoneNo"
                  type="number"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.phoneNumber, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex md6></v-flex>
            <v-flex xs12 class="pr-4" sm6 md2>
              <div
                v-if="borderType == 'Local'"
                class="subheading muted caption"
              >
                Country Code
              </div>
              <div
                v-if="borderType != 'Local'"
                class="subheading muted caption"
              >
                Country Code*
              </div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  :close-on-content-click="true"
                  attach
                  :items="countryCodes"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  v-model="secondaryCountryCode"
                  :label="
                    borderType != 'Local' ? 'Country Code*' : 'Country Code'
                  "
                  single-line
                  autocomplete="offfadsf"
                  class="pt-0 currencyTitle"
                  required
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div
                v-if="borderType == 'Local'"
                class="subheading muted caption"
              >
                Secondary Phone Number
              </div>
              <div
                v-if="borderType != 'Local'"
                class="subheading muted caption"
              >
                Secondary Phone Number*
              </div>
              <div class="heading">
                <v-text-field
                  :label="
                    borderType != 'Local'
                      ? 'Secondary Phone Number*'
                      : 'Secondary Phone Number'
                  "
                  type="number"
                  v-model="secondaryPhoneNo"
                  single-line
                  class="pt-0 currencyTitle"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex md6></v-flex>
            <v-flex xs12 class="pr-4" sm6 md2>
              <div class="subheading muted caption">Country Code</div>
              <div class="heading">
                <v-autocomplete
                  label="Country Code"
                  style="background-color: white !important"
                  :close-on-content-click="true"
                  attach
                  single-line
                  autocomplete="offfadsf"
                  :items="countryCodes"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  v-model="thirdCountryCode"
                  class="pt-0 currencyTitle"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Optional Phone Number</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Optional Phone Number"
                  class="pt-0 currencyTitle"
                  v-model="thirdPhoneNo"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex md6></v-flex>
            <v-flex xs12 class="pr-4" sm12 md8>
              <div class="subheading muted caption">Address</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Address"
                  class="pt-0 currencyTitle"
                  v-model="address"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md5>
              <div class="subheading muted caption">City</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="City"
                  :id="`autocomplete1`"
                  v-model="city"
                  placeholder=" Choose City"
                  @input="updateCity()"
                  class="pt-0 currencyTitle"
                  required
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="countrySortName != 'PAK'">
              <div class="subheading muted caption">Postal Code</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Postal Code"
                  class="pt-0 currencyTitle"
                  v-model="postCode"
                ></v-text-field>
              </div>
            </v-flex>
          </v-layout>

          <hr
            class="divider-rule"
            v-if="
              this.UserType == 1 || this.UserType == 2 || this.UserType == 3
            "
          />
          <div
            v-if="this.UserType == 1 && this.countrySortName != 'PAK'"
            class="heading primary-clr pt-3"
          >
            Nationality Proof
          </div>
          <div
            v-if="this.UserType == 1 && this.countrySortName == 'PAK'"
            class="heading primary-clr pt-3"
          >
            CNIC Proof
          </div>
          <v-layout wrap v-if="this.UserType == 1">
            <v-flex xs12 class="pr-4" sm6 md3>
              <div
                v-if="this.countrySortName != 'PAK'"
                class="subheading muted caption"
              >
                Nationality*
              </div>
              <div
                v-if="this.countrySortName == 'PAK'"
                class="subheading muted caption"
              >
                CNIC*
              </div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  :close-on-content-click="true"
                  attach
                  :items="nationalities"
                  v-model="nationality"
                  :label="countrySortName != 'PAK' ? 'Nationality*' : 'CNIC*'"
                  single-line
                  autocomplete="offfadsf"
                  class="pt-0 currencyTitle"
                  required
                  :rules="[rules.required]"
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="borderType != 'Local'">
              <div
                v-if="this.countrySortName != 'PAK'"
                class="subheading muted caption"
              >
                National ID (e.g. 784199087638641)
              </div>
              <div
                v-if="this.countrySortName == 'PAK'"
                class="subheading muted caption"
              >
                CNIC ID (e.g. 784199087638641)
              </div>
              <div class="heading">
                <v-text-field
                  :label="
                    countrySortName != 'PAK'
                      ? 'National ID (e.g. 784199087638641)'
                      : 'CNIC ID (e.g. 784199087638641)'
                  "
                  v-model="nationalId"
                  single-line
                  class="pt-0 currencyTitle"
                  :value="nationalId"
                  counter
                  maxlength="16"
                ></v-text-field>
                <p class="error-msg" v-if="nationalId.length > 16">
                  Maximum 16 Digits*
                </p>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4 marginTop20"
              sm6
              md3
              v-if="borderType != 'Local'"
            >
              <div class="upload-btn-wrapper">
                <button v-if="this.countrySortName != 'PAK'" class="btn">
                  Upload National ID Picture
                </button>
                <button v-if="this.countrySortName == 'PAK'" class="btn">
                  Upload CNIC ID Picture
                </button>
                <input
                  type="file"
                  ref="fileNationalId"
                  id="fileNationalId"
                  name="myfile"
                  @change="nationalIDChanged"
                />
              </div>
              <div v-if="nationalIdImage" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="nationalIdImage">
                  <button
                    v-if="this.countrySortName != 'PAK'"
                    type="button"
                    class="m-10"
                  >
                    View National ID
                  </button>
                  <button
                    v-if="this.countrySortName == 'PAK'"
                    type="button"
                    class="m-10"
                  >
                    View CNIC ID
                  </button>
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="borderType != 'Local'">
              <div
                v-if="this.countrySortName != 'PAK'"
                class="subheading muted caption"
              >
                National ID Expiry
              </div>
              <div
                v-if="this.countrySortName == 'PAK'"
                class="subheading muted caption"
              >
                CNIC ID Expiry
              </div>
              <div class="heading">
                <v-menu
                  ref="nationalIdDateBool"
                  lazy
                  v-model="nationalIdDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="nationalIdDatePicker"
                >
                  <v-text-field
                    slot="activator"
                    :label="
                      countrySortName != 'PAK'
                        ? 'National ID Expiry'
                        : 'CNIC ID Expiry'
                    "
                    required
                    single-line
                    class="pt-0 currencyTitle"
                    v-model="nationalIdDatePicker"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="nationalIdDatePicker"
                    class="minHeight"
                    :min="dateToday"
                    @change="
                      $refs.nationalIdDateBool.save(nationalIdDatePicker)
                    "
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex
              v-if="countrySortName != 'PAK' && borderType != 'Local'"
              xs12
              class="pr-4"
              sm6
              md3
            >
              <div class="subheading muted caption">Passport Number*</div>
              <div class="heading">
                <v-text-field
                  label="
                  Passport Number*
                  "
                  v-model="passPortNumber"
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  :value="passPortNumber"
                  single-line
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex
              v-if="countrySortName != 'PAK' && borderType != 'Local'"
              xs12
              class="pr-4 marginTop20"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Passport Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="filePassport"
                  id="filePassport"
                  @change="passportChanged"
                />
              </div>
              <div v-if="passPortImage" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="passPortImage">
                  <button type="button" class="m-10">View Passport</button>
                </a>
              </div>
            </v-flex>
            <v-flex
              v-if="countrySortName != 'PAK' && borderType != 'Local'"
              xs12
              class="pr-4"
              sm6
              md3
            ></v-flex>
            <v-flex
              v-if="countrySortName != 'PAK' && borderType != 'Local'"
              class="pr-3"
              xs12
              md3
            >
              <div class="subheading muted caption">Passport Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="passportDateBool"
                  lazy
                  v-model="passportDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="passportExpiryDate"
                >
                  <v-text-field
                    slot="activator"
                    label="Passport Expiry*
                      
                    "
                    single-line
                    class="pt-0 currencyTitle"
                    v-model="passportExpiryDate"
                    prepend-icon="event"
                    :rules="[rules.required]"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="passportExpiryDate"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.passportDateBool.save(passportExpiryDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex v-if="countrySortName != 'PAK'" xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Emirates Id*</div>
              <div class="heading">
                <v-text-field
                  label="Emirates Id*"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  v-model="emiratesID"
                  single-line
                  class="pt-0 currencyTitle"
                  :value="emiratesID"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex
              v-if="countrySortName != 'PAK'"
              xs12
              class="pr-4 marginTop20"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Emirates ID Front Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileEmirates"
                  id="fileEmirates"
                  @change="emiratesIdChangedFront"
                />
              </div>
              <div v-if="emiratesIDImageFront" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="emiratesIDImageFront">
                  <button type="button" class="m-10">View Emirates Id</button>
                </a>
              </div>
            </v-flex>
            <v-flex
              v-if="countrySortName != 'PAK'"
              xs12
              class="pr-4 marginTop20"
              sm6
              md3
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload EmiratesID Back Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileEmiratesB"
                  id="fileEmiratesB"
                  @change="emiratesIdChangedBack"
                />
              </div>
              <div v-if="emiratesIDImageBack" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="emiratesIDImageBack">
                  <button type="button" class="m-10">View Emirates Id</button>
                </a>
              </div>
            </v-flex>
            <v-flex v-if="countrySortName != 'PAK'" class="pr-3" xs12 md3>
              <div class="subheading muted caption">Emirates ID Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="emiratesDateBool"
                  lazy
                  v-model="emiratesDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="emiratesDatePicker"
                >
                  <v-text-field
                    slot="activator"
                    :rules="[rules.required]"
                    :label="x.showDate || 'Emirates ID Expiry*'"
                    required
                    single-line
                    class="pt-0 currencyTitle"
                    v-model="emiratesDatePicker"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="emiratesDatePicker"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.emiratesDateBool.save(emiratesDatePicker)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>

            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Preferred Location*</div>
              <div class="heading">
                <v-autocomplete
                  label="Preferred Location*"
                  :items="preferredLocations"
                  multiple
                  class="pt-0 currencyTitle"
                  v-model="preferredLocation"
                  single-line
                  :rules="[rules.requiredLocation]"
                />
              </div>
            </v-flex>
          </v-layout>
          <div
            v-if="this.UserType == 2 || this.UserType == 3"
            class="heading primary-clr pt-3"
          >
            Driver's License & Vehicle Information
          </div>

          <v-layout row wrap v-if="this.UserType == 2 || this.UserType == 3">
            <v-flex v-if="this.UserType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Flight Name*</div>
              <div class="heading">
                <v-text-field
                  label="Flight Name*"
                  v-model="FlightName"
                  :value="FlightName"
                  class="pt-0 currencyTitle"
                  single-line
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.UserType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Flight No.*</div>
              <div class="heading">
                <v-text-field
                  label="Flight No.*"
                  v-model="WesselNo"
                  :value="WesselNo"
                  class="pt-0 currencyTitle"
                  single-line
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.UserType == 3" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">Container No.*</div>
              <div class="heading">
                <v-text-field
                  label="Container No.*"
                  v-model="WesselNoSea"
                  :value="WesselNoSea"
                  class="pt-0 currencyTitle"
                  single-line
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.UserType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Other AIR CARGO flight details*
              </div>
              <div class="heading">
                <v-text-field
                  label="Other AIR CARGO flight details*"
                  v-model="AirCargoDetails"
                  :value="AirCargoDetails"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.UserType == 3" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Other SEA CARGO details*
              </div>
              <div class="heading">
                <v-text-field
                  label="Other SEA CARGO details*"
                  v-model="SeaCargoDetails"
                  :value="SeaCargoDetails"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.UserType == 2" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Tracking link or details*
              </div>
              <div class="heading">
                <v-text-field
                  label="Tracking link or details*"
                  v-model="TrackingLinkAir"
                  single-line
                  :value="TrackingLinkAir"
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex v-if="this.UserType == 3" xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Tracking link or details*
              </div>
              <div class="heading">
                <v-text-field
                  label="Tracking link or details*"
                  v-model="TrackingLinkSea"
                  single-line
                  class="pt-0 currencyTitle"
                  :value="TrackingLinkSea"
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
          </v-layout>

          <hr class="divider-rule" />
          <div v-if="this.UserType == 1" class="heading primary-clr pt-3">
            Driver's License & Vehicle Information
          </div>
          <v-layout wrap v-if="this.UserType == 1">
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Driving License No.*</div>
              <div class="heading">
                <v-text-field
                  label="Driving License No.*"
                  v-model="licenseNumber"
                  :value="licenseNumber"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4 marginTop20" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload License Front Picture</button>
                <input
                  type="file"
                  ref="fileLicense"
                  id="fileLicense"
                  name="myfile"
                  @change="licenseChanged"
                />
              </div>
              <br />
              <div v-if="licenseImage" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="licenseImage">
                  <button type="button" class="m-10">View License</button>
                </a>
              </div>

              <!-- <span class="font16">License Image* &nbsp;&nbsp;</span><input type="file" label="License Picture" @change="licenseChanged" required> -->
            </v-flex>
            <v-flex xs12 class="pr-4 marginTop20" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload License Back Picture</button>
                <input
                  type="file"
                  ref="fileLicenseB"
                  id="fileLicenseB"
                  name="myfile"
                  @change="licenseChangedBack"
                />
              </div>
              <br />
              <div v-if="licenseImageBack" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="licenseImageBack">
                  <button type="button" class="m-10">View License</button>
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3>
              <div class="subheading muted caption">License Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="licenseDateBool"
                  lazy
                  v-model="licenseDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="licenseDatePicker"
                >
                  <v-text-field
                    slot="activator"
                    :rules="[rules.required]"
                    :label="x.showDate || 'License Expiry*'"
                    required
                    class="pt-0 currencyTitle"
                    single-line
                    v-model="licenseDatePicker"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="licenseDatePicker"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.licenseDateBool.save(licenseDatePicker)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">
                Vehicle Registration / MULKIYA No.*
              </div>
              <div class="heading">
                <v-text-field
                  label="Vehicle Registration / MULKIYA No.*"
                  v-model="vehicleReg"
                  :value="vehicleReg"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4 marginTop20" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Front Registration Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileVehicleReg"
                  id="fileVehicleReg"
                  @change="vehicleRegistrationChanged"
                />
              </div>
              <div v-if="vehicleRegImage" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="vehicleRegImage">
                  <button type="button" class="m-10">
                    View Vehicle Registration
                  </button>
                </a>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4 marginTop20" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Back Registration Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileVehicleRegB"
                  id="fileVehicleRegB"
                  @change="vehicleRegistrationChangedBack"
                />
              </div>
              <div v-if="vehicleRegImageBack" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="vehicleRegImageBack">
                  <button type="button" class="m-10">
                    View Vehicle Registration
                  </button>
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3>
              <div class="subheading muted caption">Registration Expiry*</div>
              <div class="heading">
                <v-menu
                  ref="registrationDateBool"
                  lazy
                  v-model="registrationDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="registrationDatePicker"
                >
                  <v-text-field
                    slot="activator"
                    :label="x.showDate || 'Registration Expiry*'"
                    required
                    single-line
                    class="pt-0 currencyTitle"
                    :rules="[rules.required]"
                    v-model="registrationDatePicker"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="registrationDatePicker"
                    :min="dateToday"
                    class="minHeight"
                    @change="
                      $refs.registrationDateBool.save(registrationDatePicker)
                    "
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="borderType != 'Local'">
              <div class="subheading muted caption">Vehicle Insurance No.</div>
              <div class="heading">
                <v-text-field
                  label="Vehicle Insurance No."
                  v-model="vehicleInsurance"
                  :value="vehicleInsurance"
                  single-line
                  class="pt-0 currencyTitle"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4 marginTop20"
              sm6
              md3
              v-if="borderType != 'Local'"
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Insurance Picture</button>
                <input
                  type="file"
                  name="myfile"
                  ref="fileInsurance"
                  id="fileInsurance"
                  @change="vehicleInsuranceChanged"
                />
              </div>
              <div v-if="vehicleInsuranceImage" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="vehicleInsuranceImage">
                  <button type="button" class="m-10">
                    View Vehicle Insurance
                  </button>
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3 xs12 md3" v-if="borderType != 'Local'"></v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="borderType != 'Local'">
              <div
                v-if="this.countrySortName != 'PAK'"
                class="subheading muted caption"
              >
                Insurance Expiry*
              </div>
              <div
                v-if="this.countrySortName == 'PAK'"
                class="subheading muted caption"
              >
                Insurance Expiry
              </div>
              <div class="heading">
                <v-menu
                  ref="insuranceDateBool"
                  lazy
                  v-model="insuranceDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="insuranceDatePicker"
                >
                  <v-text-field
                    slot="activator"
                    :label="
                      x.showDate || countrySortName != 'PAK'
                        ? 'Insurance Expiry*'
                        : 'Insurance Expiry'
                    "
                    required
                    class="pt-0 currencyTitle"
                    single-line
                    v-model="insuranceDatePicker"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="insuranceDatePicker"
                    class="minHeight"
                    :min="dateToday"
                    @change="$refs.insuranceDateBool.save(insuranceDatePicker)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3 v-if="borderType != 'Local'">
              <div class="subheading muted caption">Vehicle Auth Letter</div>
              <div class="heading">
                <v-text-field
                  label="Vehicle Auth Letter"
                  v-model="vehicleAuthLetter"
                  single-line
                  :value="vehicleAuthLetter"
                  class="pt-0 currencyTitle"
                ></v-text-field>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4 marginTop20"
              sm6
              md3
              v-if="borderType != 'Local'"
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Auth Letter Proof</button>
                <input
                  type="file"
                  ref="fileAuth"
                  id="fileAuth"
                  name="myfile"
                  @change="vehicleAuthLetterChanged"
                />
              </div>
              <div v-if="vehicleAuthLetterImage" class="upload-btn-wrapper">
                <a target="_blank" v-bind:href="vehicleAuthLetterImage">
                  <button type="button" class="m-10">View Auth Letter</button>
                </a>
              </div>
            </v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="borderType != 'Local'"></v-flex>
            <v-flex class="pr-3" xs12 md3 v-if="borderType != 'Local'">
              <div class="subheading muted caption">Auth Letter Expiry</div>
              <div class="heading">
                <v-menu
                  ref="authDateBool"
                  lazy
                  v-model="authDateBool"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="authDatePicker"
                >
                  <v-text-field
                    slot="activator"
                    :label="x.showDate || 'Auth Letter Expiry'"
                    required
                    class="pt-0 currencyTitle"
                    single-line
                    v-model="authDatePicker"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="authDatePicker"
                    :min="dateToday"
                    class="minHeight"
                    @change="$refs.authDateBool.save(authDatePicker)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-flex>
          </v-layout>
          <v-spacer />
          <v-spacer />
          <v-layout wrap v-if="this.UserType == 1">
            <v-flex xs12 class="pr-4" sm6 md2>
              <div class="subheading muted caption">Prefix*</div>
              <div class="heading">
                <v-autocomplete
                  label="Prefix*"
                  :items="prefixTrucks"
                  autocomplete="offfadsf"
                  class="pt-0 currencyTitle"
                  v-model="truckPrefix"
                  single-line
                  :rules="[rules.required]"
                />
              </div>
            </v-flex>
            <v-flex
              xs12
              class="pr-4"
              sm6
              md3
              v-if="
                this.countrySortName == 'PAK' && this.truckPrefix == 'Others'
              "
            >
              <div class="subheading muted caption">Truck Prefix*</div>
              <div class="heading">
                <v-text-field
                  label="Truck Prefix*"
                  single-line
                  v-model="truckPrefixPak"
                  :value="truckPrefixPak"
                  class="pt-0 currencyTitle"
                  :rules="[rules.required]"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md3>
              <div class="subheading muted caption">Truck Number*</div>
              <div class="heading">
                <v-text-field
                  type="number"
                  label="Truck Number*"
                  min="1"
                  single-line
                  v-model="truckNumber"
                  :value="truckNumber"
                  class="pt-0 currencyTitle"
                  :rules="[rules.required, rules.greaterthan]"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                Truck Capacity in Tonnes*
              </div>
              <div class="heading">
                <v-text-field
                  type="number"
                  label="Truck Capacity in Tonnes*"
                  single-line
                  class="pt-0 currencyTitle"
                  v-model="truckCapacity"
                  :value="truckCapacity"
                  :rules="[rules.required, rules.greaterthan]"
                />
              </div>
            </v-flex>
            <v-flex class="pr-3 pt-3" xs12 md4>
              <div class="subheading muted caption">Category of truck*</div>
              <div class="heading">
                <v-select
                  :items="trucks"
                  label="Category of truck*"
                  :rules="[rules.required]"
                  class="pt-0 currencyTitle"
                  v-model="truckCat"
                  single-line
                />
              </div>
            </v-flex>
            <v-flex class="pr-3 pt-3" xs12 md4>
              <div class="subheading muted caption">Sub-category of truck*</div>
              <div class="heading">
                <v-select
                  :items="trucksSub"
                  label="Sub-category of truck*"
                  :rules="[rules.required]"
                  v-model="truckType"
                  class="pt-0 currencyTitle"
                  single-line
                />
              </div>
            </v-flex>
          </v-layout>

          <hr v-if="this.UserType == 1" class="divider-rule" />
          <div class="heading primary-clr pt-3">
            {{
              countrySortName != "PAK"
                ? "Sponsor Information"
                : "Guarantor Information"
            }}
          </div>
          <v-layout wrap>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                {{
                  countrySortName != "PAK" ? "Sponsor Name" : "Guarantor Name"
                }}
              </div>
              <div class="heading">
                <v-text-field
                  single-line
                  :label="
                    countrySortName != 'PAK' ? 'Sponsor Name' : 'Guarantor Name'
                  "
                  class="pt-0 currencyTitle"
                  v-model="sponsorName"
                  :value="sponsorName"
                />
              </div>
            </v-flex>
            <v-flex xs12 class="pr-4" sm6 md4>
              <div class="subheading muted caption">
                {{
                  countrySortName != "PAK"
                    ? "Sponsor Phone number"
                    : "Guarantor Phone number"
                }}
              </div>
              <div class="heading">
                <v-text-field
                  single-line
                  :label="
                    countrySortName != 'PAK'
                      ? 'Sponsor Phone number'
                      : 'Guarantor Phone number'
                  "
                  class="pt-0 currencyTitle"
                  v-model="sponsorPhone"
                  :value="sponsorPhone"
                />
              </div>
            </v-flex>
          </v-layout>
          <div
            class="my-3"
            v-if="haserror"
            style="width: 50%; margin: auto; text-align: center"
          >
            <v-alert v-model="haserror" class="py-2" outline color="red">{{
              x.error
            }}</v-alert>
          </div>
          <hr class="divider-rule" />
          <v-layout>
            <v-spacer />
            <v-btn
              size="large"
              color="grey darken-3"
              flat
              style="color: white"
              @click.native="close"
              >Cancel</v-btn
            >
            <v-btn
              size="large"
              color="orange darken-1"
              @click.native="checkAdd()"
              style="color: white"
              :disabled="loading"
              >Register Driver</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>
      <success-dialog
        content="Driver registration successful"
        :show="x.registerSuccess"
        :onclose="closeRegistration"
      />
      <v-snackbar
        :timeout="4000"
        bottom
        color="red darken-2"
        v-model="errorCropper"
        class="white--text"
        v-if="errorCropper"
        >{{ errorCropper }}</v-snackbar
      >
    </v-container>
    <v-dialog
      v-model="confirmationDialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar
          dark
          style="background-color: #fee9d0; color: black; padding-left: 100px"
          flat
        >
          <v-toolbar-title>Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text style="text-align: center" class="pa-4">
            <div class="pb-4">
              <img
                src="../../assets/Character-sitting.png"
                height="300"
                width="300"
              />
            </div>
            <div>
              Are you sure want to change driver type? <br />Deselecting will
              unassign all transporters.
            </div>
          </v-card-text>

          <v-card-actions class="pt-2 pb-5" style="justify-content: center">
            <v-btn
              medium
              style="text-transform: none; !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="noConfirmation()"
              >No</v-btn
            >

            <v-btn
              medium
              style="text-transform: none; !important"
              color="green white--text "
              darken-1
              @click="yesConfirmation()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { TruckPrefix, countries } from "../../constants/constants";
import { EventBus } from "../../event-bus.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ImageCropper from "@/components/Common/ImageCropper.vue";
import moment from "moment";
import { TruckTypes } from "../../constants/constants";
import { TruckArray } from "../../constants/constants";
import Loading from "vue-loading-overlay";
import { StorageKeys, UserTypes } from "../../constants/constants";
export default {
  created() {
    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete("1");
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);
    this.fetchBank();
    this.$eventBus.$on("error-image", () => {
      this.errorCropper =
        "Please upload image of file type png , jpg ,jpeg or pdf!";
    });
    this.$eventBus.$on("crop-profile-image", (cropImage) => {
      if (this.status == 1) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.profilePicURL = cropImage;
        this.createCropImage(cropImage, 1);
      }
    });
    this.$eventBus.$on("crop-national-image", (cropImage) => {
      if (this.status == 2) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.nationalIdImage = cropImage;
        this.createCropImage(cropImage, 2);
      }
    });
    this.$eventBus.$on("crop-passport-image", (cropImage) => {
      if (this.status == 3) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.passPortImage = cropImage;
        this.createCropImage(cropImage, 3);
      }
    });
    this.$eventBus.$on("crop-license-image", (cropImage) => {
      if (this.status == 4) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.licenseImage = cropImage;
        this.createCropImage(cropImage, 4);
      }
    });
    this.$eventBus.$on("crop-vehicleRegImage-image", (cropImage) => {
      if (this.status == 5) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.vehicleRegImage = cropImage;
        this.createCropImage(cropImage, 5);
      }
    });
    this.$eventBus.$on("crop-vehicleInsuranceImage-image", (cropImage) => {
      if (this.status == 6) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.vehicleInsuranceImage = cropImage;
        this.createCropImage(cropImage, 6);
      }
    });
    this.$eventBus.$on("crop-vehicleAuthLetterImage-image", (cropImage) => {
      if (this.status == 7) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.vehicleAuthLetterImage = cropImage;
        this.createCropImage(cropImage, 7);
      }
    });
    this.$eventBus.$on("crop-licenseImageBack-image", (cropImage) => {
      if (this.status == 8) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.licenseImageBack = cropImage;
        this.createCropImage(cropImage, 8);
      }
    });
    this.$eventBus.$on("crop-vehicleRegImageBack-image", (cropImage) => {
      if (this.status == 9) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.vehicleRegImageBack = cropImage;
        this.createCropImage(cropImage, 9);
      }
    });
    this.$eventBus.$on("crop-emiratesIDImageFront-image", (cropImage) => {
      if (this.status == 10) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.emiratesIDImageFront = cropImage;
        this.createCropImage(cropImage, 10);
      }
    });
    this.$eventBus.$on("crop-emiratesIDImageBack-image", (cropImage) => {
      if (this.status == 11) {
        this.cropperModal = false;
        this.cropFileUrl = "";
        this.emiratesIDImageBack = cropImage;
        this.createCropImage(cropImage, 11);
      }
    });

    this.$eventBus.$on("cropper-close", () => {
      this.cropperModal = false;
    });
    this.countrySortName = this.$route.params.country;
    this.getTransporterList();
    this.UserType = "1";
    this.transporterId = this.$route.params.id;
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "transporter-driver-profile-new") {
        this.$router.push(`/transporter`);
      }
    });

    this.trucks = TruckArray;
    this.UserTypes = UserTypes;
    this.countryCodes = countries;
    this.prefixTrucks = TruckPrefix;
    delete this.axios.defaults.headers.common["token"];
    const url = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url).then((response) => {
      if (response.status === 200) {
        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    SuccessDialog,
    Loading,
    ImageCropper,
  },
  props: {
    profile: Object,
  },

  data() {
    return {
      mainError: null,
      mainError1: null,
      bankHolderName: "",
      bankName: "",
      bankInfo: [],
      errorCropper: null,
      invoiceDay: null,
      city1Error: null,
      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      transporters: [],
      errorCropper: null,
      transporterId: [],
      countrySortName: "",
      Transporter: false,
      Owner: false,
      cropperModal: false,
      loading: false,
      type: ["Transporter"],
      cropFileUrl: "",
      image: "../../assets/v_logo_new.png",
      multipleCountry: [],
      lazy: false,
      valid: true,
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      trucksSub: [],
      truckCat: "",
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        requiredLocation: (value) =>
          !!value.length || "This field is required.",
        onlyEnglish: (v) =>
          /^[a-zA-Z\s]*$/.test(v) || "Please Enter Valid value",
        greaterthan: (value) => value >= 1 || "Please enter valid number",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        arabicLanguage: (v) =>
          /^([أ-ي]| [\u0600-\u06FF]|[@~`!@#$%^&*()_=+]|[الآخرين]|[ "]|['])+$/.test(
            v
          ) || "Please Enter Arabic language",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No. ",
      },
      // ---------------- EXPIRY DATES -------------
      nationalIdDatePicker: "",
      nationalIdDateBool: false,
      nationalIdExpiryDate: "",

      passportDatePicker: "",
      passportDateBool: false,
      passportExpiryDate: "",

      licenseDatePicker: "",
      licenseDateBool: false,
      licenseExpiryDate: "",
      emiratesDatePicker: "",
      emiratesDateBool: false,
      emiratesIDExpiryDate: "",

      registrationDatePicker: "",
      registrationDateBool: false,
      regExpiryDate: "",

      insuranceDatePicker: "",
      insuranceDateBool: false,
      insuranceExpiryDate: "",

      authDatePicker: "",
      authDateBool: false,
      authLetterExpiryDate: "",
      // -------------------------------------------

      firstName: "",
      TrackingLinkAir: "",
      TrackingLinkSea: "",
      SeaCargoDetails: "",
      AirCargoDetails: "",
      WesselNo: "",
      WesselNoSea: "",
      FlightName: "",
      lastName: "",
      UserType: "",
      UserTypes: [],
      borderType: "",
      borderTypes: [
        { text: "Local Driver", value: "Local" },
        { text: "Cross Country Driver", value: "OutOfBorder" },
      ],
      dob: moment().unix(),
      nationality: "",
      countryCode: "",
      secondaryCountryCode: "",
      thirdCountryCode: "",
      phoneNo: null,
      secondaryPhoneNo: null,
      thirdPhoneNo: null,
      //   email: "",
      address: "",
      city: "",

      //   state: "",
      postCode: "",
      profilePicURL: "",
      status: null,
      nationalId: "",
      nationalIdImage: "",

      passPortNumber: "",
      passPortImage: "",
      emiratesID: "",
      emiratesIDImageBack: "",
      emiratesIDImageFront: "",
      licenseNumber: "",
      licenseImage: "",
      licenseImageBack: "",
      sponsorName: "",
      sponsorPhone: "",
      truckCapacity: "",
      vehicleReg: "",
      vehicleRegImage: "",
      vehicleRegImageBack: "",
      vehicleInsurance: "",
      vehicleInsuranceImage: "",
      vehicleAuthLetter: "",
      vehicleAuthLetterImage: "",
      // qrCode: "NA",
      truckTypes: TruckTypes,
      truckType: "",
      vehicleNumber: "",
      truckNumber: "",

      prefixTrucks: [],
      truckPrefix: null,
      truckPrefixPak: null,
      preferredLocations: [],
      preferredLocation: [],
      fingerprint: null,
      countryCodes: [],
      nationalities: [
        {
          text: "Indian",
          value: "Indian",
        },
        {
          text: "Pakistani",
          value: "pakistani",
        },
        {
          text: "Sudani",
          value: "sudani",
        },
        {
          text: "Yemeni",
          value: "yemeni",
        },
        {
          text: "Egyptian",
          value: "egyptian",
        },
        {
          text: "Nepali",
          value: "nepali",
        },
        {
          text: "Sri Lankan",
          value: "sri lankan",
        },
        {
          text: "Bangladeshi",
          value: "bangladeshi",
        },
        {
          text: "Syrian",
          value: "syrian",
        },
        {
          text: "Saudi Arabian",
          value: "saudi Arabian",
        },
        {
          text: "Omani",
          value: "omani",
        },
        {
          text: "Bahraini",
          value: "bahraini",
        },
        {
          text: "Kuwaiti",
          value: "kuwaiti",
        },
        {
          text: "Others",
          value: "others",
        },
      ],
      x: {
        loading: false,
        registerSuccess: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
    };
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  watch: {
    truckCat(val) {
      var x = this.trucks.filter((v) => {
        if (v.value === val) return v;
      });
      this.trucksSub = x[0].sub;
    },
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    initLoadingAutocomplete(n) {
      // console.log(n);
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1")
      );
      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        // console.log(place);
        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.city1Error = false;
        } else {
          this.city1Error = true;
        }
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.city = x[0];
        } else {
          this.city = y[0];
        }
      });

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.

      // console.log(this.dropoff);
    },
    updateCity() {
      if (this.city1Error == false) {
        this.city1Error = true;
      }
    },
    fetchBank() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      let { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/bankListForDriver`, body, config)
        .then(
          (response) => {
            let data = response.data.data;
            console.log(data);
            this.bankInfo = data;
            // data.forEach((element) => {
            //   this.bankInfo.push({
            //     text: element.bankName,
            //     value: element._id,
            //   });
            // });
            // console.log(this.banking.info);

            // this.bankName = response.data.data[2].bankName;
          },
          (error) => {
            this.error = "Something went wrong. Please try again later!";
            this.loading = false;
          }
        );
    },
    test() {
      var canvas = document.getElementById("canvas");
      var context = canvas.getContext("2d");
      context.fillStyle = "rgba(255, 255, 0, .5)";
      context.fillRect(0, 0, 100, 100);
      canvas.toBlob(function (blob) {
        alert(blob.type);
      }, "image/wbmp");
    },

    async checkAdd() {
      this.operationName = "register-new-driver";
      this.loading = true;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.register();
      } else {
        this.loading = false;
        return;
      }
    },
    getTransporterList() {
      this.userId = localStorage.getItem("userId");
      let url = "/dmsAdmin/transporterForDriverDetail";
      delete this.axios.defaults.headers.common["token"];
      let body = {
        workingCountry: this.countrySortName,
        type: "1",
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;

          response.data.data.list.forEach((e) => {
            this.transporters.push({ value: e._id, text: e.transporterName });
          });
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to fetch transporters";
        }
      );
    },
    getUserById() {
      this.userId = localStorage.getItem("userId");
      this.loading = true;
      let url = "/admin/getUserById";
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          response.data.data.countrySortName.forEach((e) => {
            this.multipleCountry.push(e);
          });
        },
        (error) => {
          this.x.error = "Failed to fetch country";
        }
      );
    },
    save(date) {
      this.dob = moment(date, "YYYY-MM-DD").unix();
      this.$refs.menu.save(date);
    },
    getTime(epoch) {
      if (dob !== 0) {
        return moment().unix(dob).format("YYYY-MM-DD");
      }
    },

    close() {
      this.$router.go(-1);
    },

    register() {
      if (this.city1Error == true || this.city1Error == null) {
        this.loading = false;
        this.x.error = "Please enter Valid City";
        return false;
      }
      this.x.error = null;
      this.mainError = null;
      this.mainError1 = null;
      let driverType = "";
      if (this.$refs.form.validate()) {
        this.x.error = null;
        this.loading = true;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        const url = `${this.constants.apiUrl}/dmsAdmin/addDriver`;
        const { truckTypes, x, countryCodes, menu, ...driverProfile } =
          this._data;

        if (!driverProfile.profilePicURL) {
          this.x.error = "Please provide profile picture before moving ahead";
          this.loading = false;
          return false;
        }
        if (!this.type.length && this.UserType == "1") {
          this.mainError1 = "Please select checkbox";
          this.loading = false;
          return false;
        }

        if (
          this.type.length &&
          !this.transporterId &&
          this.UserType == "1" &&
          this.type.includes("Transporter")
        ) {
          this.mainError = "Please select at least one transporter";
          this.loading = false;
          return false;
        }
        if (!driverProfile.secondaryCountryCode && this.borderType != "Local") {
          this.x.error = "Please provide secondary country code moving ahead";
          this.loading = false;
          return false;
        }
        if (!driverProfile.secondaryPhoneNo && this.borderType != "Local") {
          this.x.error = "Please provide secondary phone number moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.passPortNumber &&
          this.UserType == "1" &&
          this.countrySortName != "PAK" &&
          this.borderType != "Local"
        ) {
          this.x.error = "Please provide passport number before moving ahead";
          this.loading = false;
          return false;
        }

        if (
          !driverProfile.passPortImage &&
          this.UserType == "1" &&
          this.countrySortName != "PAK" &&
          this.borderType != "Local"
        ) {
          this.x.error = "Please provide passport image before moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.passportExpiryDate &&
          this.UserType == "1" &&
          this.countrySortName != "PAK" &&
          this.borderType != "Local"
        ) {
          this.x.error =
            "Please provide passport expiry date before moving ahead";
          this.loading = false;
          return false;
        }

        if (
          !driverProfile.emiratesIDImageFront &&
          this.UserType == "1" &&
          this.countrySortName != "PAK"
        ) {
          this.x.error = "Please provide Emirates ID image before moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.emiratesIDImageBack &&
          this.UserType == "1" &&
          this.countrySortName != "PAK"
        ) {
          this.x.error = "Please provide Emirates ID image before moving ahead";
          this.loading = false;
          return false;
        }

        if (!driverProfile.licenseImage && this.UserType == "1") {
          this.x.error = "Please provide license image before moving ahead";
          this.loading = false;
          return false;
        }
        if (!driverProfile.licenseImageBack && this.UserType == "1") {
          this.x.error = "Please provide license image before moving ahead";
          this.loading = false;
          return false;
        }

        if (!driverProfile.vehicleRegImage && this.UserType == "1") {
          this.x.error =
            "Please provide vehicle registration image before moving ahead";
          this.loading = false;
          return false;
        }
        if (!driverProfile.vehicleRegImageBack && this.UserType == "1") {
          this.x.error =
            "Please provide vehicle registration image before moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.registrationDatePicker &&
          this.UserType == "1" &&
          this.countrySortName != "PAK"
        ) {
          this.x.error =
            "Please provide registration ID expiry date before moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.vehicleInsurance.trim() &&
          this.UserType == "1" &&
          this.countrySortName != "PAK" &&
          this.borderType != "Local"
        ) {
          this.x.error =
            "Please provide vehicle insurance number before moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.vehicleInsuranceImage &&
          this.UserType == "1" &&
          this.countrySortName != "PAK" &&
          this.borderType != "Local"
        ) {
          this.x.error =
            "Please provide vehicle insurance image before moving ahead";
          this.loading = false;
          return false;
        }
        if (
          !driverProfile.insuranceDatePicker &&
          this.UserType == "1" &&
          this.countrySortName != "PAK" &&
          this.borderType != "Local"
        ) {
          this.x.error =
            "Please provide insurance ID expiry date before moving ahead";
          this.loading = false;
          return false;
        }

        var obj = {
          // --------- required fields-----------
          firstName: driverProfile.firstName.trim(),
          lastName: driverProfile.lastName.trim(),
          jobType: driverProfile.UserType,
          countryCode: driverProfile.countryCode,
          phoneNo: driverProfile.phoneNo,
          profilePicURL: driverProfile.profilePicURL,
          workingCountry: driverProfile.countrySortName,
          driverPaymentDuration: driverProfile.invoiceDay,
        };
        if (this.UserType == "1") {
          if (this.type.length) {
            obj.ownerType = this.type;
            if (this.type.includes("Transporter")) {
              obj.ownerId = [this.transporterId];
            } else {
              obj.ownerId = [];
            }
          }
        }
        if (this.bankName) {
          obj.bankName = driverProfile.bankName;
        }
        if (this.bankHolderName) {
          obj.bankHolderName = driverProfile.bankHolderName;
        }

        if (this.UserType == "1") {
          if (this.countrySortName == "PAK") {
            if (this.truckPrefix == "Others") {
              obj.truckPrefix = driverProfile.truckPrefixPak;
              obj.vehicleNumber = driverProfile.truckPrefixPak
                ? driverProfile.truckPrefixPak + driverProfile.truckNumber
                : driverProfile.truckNumber;
            }
            this.secondaryPhoneNo
              ? (obj.secondaryPhoneNo = driverProfile.secondaryPhoneNo)
              : "";
            this.secondaryCountryCode
              ? (obj.secondaryCountryCode = driverProfile.secondaryCountryCode)
              : "";

            if (this.truckPrefix != "Others") {
              obj.truckPrefix = driverProfile.truckPrefix;
              obj.vehicleNumber = driverProfile.truckPrefix
                ? driverProfile.truckPrefix + driverProfile.truckNumber
                : driverProfile.truckNumber;
            }
            if (this.vehicleInsurance) {
              obj.vehicleInsurance = driverProfile.vehicleInsurance.trim();
            }
            if (this.vehicleInsuranceImage) {
              obj.vehicleInsuranceImage = driverProfile.vehicleInsuranceImage;
            }
            if (this.insuranceDatePicker) {
              obj.insuranceExpiryDate = driverProfile.insuranceDatePicker;
            }
            (obj.nationality = driverProfile.nationality),
              (obj.nationalId = driverProfile.nationalId),
              (obj.nationalIdImage = driverProfile.nationalIdImage),
              (obj.licenseNumber = driverProfile.licenseNumber.trim()),
              (obj.licenseImage = driverProfile.licenseImage),
              (obj.licenseImageBack = driverProfile.licenseImageBack),
              (obj.truckType = driverProfile.truckCat),
              (obj.subTruckType = driverProfile.truckType),
              (obj.truckCapacity = driverProfile.truckCapacity),
              (obj.vehicleReg = driverProfile.vehicleReg.trim()),
              (obj.vehicleRegImage = driverProfile.vehicleRegImage),
              (obj.vehicleRegImageBack = driverProfile.vehicleRegImageBack),
              (obj.truckNumber = driverProfile.truckNumber),
              (obj.licenseExpiryDate = driverProfile.licenseDatePicker),
              (obj.regExpiryDate = driverProfile.registrationDatePicker),
              (obj.nationalIdExpiryDate = driverProfile.nationalIdDatePicker),
              (obj.preferredLocation = driverProfile.preferredLocation);
          } else {
            if (this.borderType == "Local") {
              this.secondaryPhoneNo
                ? (obj.secondaryPhoneNo = driverProfile.secondaryPhoneNo)
                : "";
              this.secondaryCountryCode
                ? (obj.secondaryCountryCode =
                    driverProfile.secondaryCountryCode)
                : "";
              (obj.nationality = driverProfile.nationality),
                (obj.borderType = driverProfile.borderType),
                (obj.emiratesID = driverProfile.emiratesID.trim()),
                (obj.emiratesIDImageFront = driverProfile.emiratesIDImageFront),
                (obj.emiratesIDImageBack = driverProfile.emiratesIDImageBack),
                (obj.licenseNumber = driverProfile.licenseNumber.trim()),
                (obj.licenseImage = driverProfile.licenseImage),
                (obj.licenseImageBack = driverProfile.licenseImageBack),
                (obj.truckType = driverProfile.truckCat),
                (obj.subTruckType = driverProfile.truckType),
                (obj.truckPrefix = driverProfile.truckPrefix),
                (obj.truckCapacity = driverProfile.truckCapacity),
                (obj.vehicleReg = driverProfile.vehicleReg.trim()),
                (obj.vehicleRegImage = driverProfile.vehicleRegImage),
                (obj.vehicleRegImageBack = driverProfile.vehicleRegImageBack),
                (obj.vehicleNumber = driverProfile.truckPrefix
                  ? driverProfile.truckPrefix + driverProfile.truckNumber
                  : driverProfile.truckNumber),
                (obj.truckNumber = driverProfile.truckNumber),
                (obj.licenseExpiryDate = driverProfile.licenseDatePicker),
                (obj.emiratesIDExpiryDate = driverProfile.emiratesDatePicker),
                (obj.regExpiryDate = driverProfile.registrationDatePicker),
                (obj.preferredLocation = driverProfile.preferredLocation);
            } else {
              this.secondaryPhoneNo
                ? (obj.secondaryPhoneNo = driverProfile.secondaryPhoneNo)
                : "";
              this.secondaryCountryCode
                ? (obj.secondaryCountryCode =
                    driverProfile.secondaryCountryCode)
                : "";
              (obj.nationality = driverProfile.nationality),
                (obj.nationalId = driverProfile.nationalId),
                (obj.nationalIdImage = driverProfile.nationalIdImage),
                (obj.passPortImage = driverProfile.passPortImage),
                (obj.borderType = driverProfile.borderType),
                (obj.emiratesID = driverProfile.emiratesID.trim()),
                (obj.emiratesIDImageFront = driverProfile.emiratesIDImageFront),
                (obj.emiratesIDImageBack = driverProfile.emiratesIDImageBack),
                (obj.licenseNumber = driverProfile.licenseNumber.trim()),
                (obj.licenseImage = driverProfile.licenseImage),
                (obj.licenseImageBack = driverProfile.licenseImageBack),
                (obj.truckType = driverProfile.truckCat),
                (obj.subTruckType = driverProfile.truckType),
                (obj.insuranceExpiryDate = driverProfile.insuranceDatePicker),
                (obj.truckPrefix = driverProfile.truckPrefix),
                (obj.truckCapacity = driverProfile.truckCapacity),
                (obj.vehicleReg = driverProfile.vehicleReg.trim()),
                (obj.vehicleRegImage = driverProfile.vehicleRegImage),
                (obj.vehicleRegImageBack = driverProfile.vehicleRegImageBack),
                (obj.vehicleInsurance = driverProfile.vehicleInsurance.trim()),
                (obj.vehicleInsuranceImage =
                  driverProfile.vehicleInsuranceImage),
                (obj.vehicleNumber = driverProfile.truckPrefix
                  ? driverProfile.truckPrefix + driverProfile.truckNumber
                  : driverProfile.truckNumber),
                (obj.truckNumber = driverProfile.truckNumber),
                (obj.insuranceExpiryDate = driverProfile.insuranceDatePicker),
                (obj.licenseExpiryDate = driverProfile.licenseDatePicker),
                (obj.emiratesIDExpiryDate = driverProfile.emiratesDatePicker),
                (obj.regExpiryDate = driverProfile.registrationDatePicker),
                (obj.nationalIdExpiryDate = driverProfile.nationalIdDatePicker),
                (obj.preferredLocation = driverProfile.preferredLocation),
                (obj.passPortNumber = driverProfile.passPortNumber.trim()),
                (obj.passPortImage = driverProfile.passPortImage),
                (obj.passportExpiryDate = driverProfile.passportExpiryDate);
            }
          }
        }
        if (this.UserType == "2") {
          (obj.truckNumber = driverProfile.WesselNo),
            (obj.flightName = driverProfile.FlightName),
            (obj.flightDetail = driverProfile.AirCargoDetails),
            (obj.trackingLink = driverProfile.TrackingLinkAir);
        }
        if (this.UserType == "3") {
          (obj.truckNumber = driverProfile.WesselNoSea),
            (obj.flightDetail = driverProfile.SeaCargoDetails),
            (obj.trackingLink = driverProfile.TrackingLinkSea);
        }

        if (driverProfile.thirdCountryCode) {
          obj.thirdCountryCode = driverProfile.thirdCountryCode;
        }
        if (driverProfile.thirdPhoneNo) {
          obj.thirdPhoneNo = driverProfile.thirdPhoneNo;
        }
        if (driverProfile.address) {
          obj.address = driverProfile.address;
        }
        if (driverProfile.city) {
          obj.city = driverProfile.city;
        }
        if (driverProfile.postCode) {
          obj.postCode = driverProfile.postCode;
        }
        if (driverProfile.sponsorName) {
          obj.sponsorName = driverProfile.sponsorName;
        }
        if (driverProfile.sponsorPhone) {
          obj.sponsorPhone = driverProfile.sponsorPhone;
        }
        if (driverProfile.vehicleAuthLetter && this.borderType != "Local") {
          obj.vehicleAuthLetter = driverProfile.vehicleAuthLetter;
        }
        if (
          driverProfile.vehicleAuthLetterImage &&
          this.borderType != "Local"
        ) {
          obj.vehicleAuthLetterImage = driverProfile.vehicleAuthLetterImage;
        }

        if (driverProfile.authDatePicker && this.borderType != "Local") {
          obj.authLetterExpiryDate = driverProfile.authDatePicker;
        }

        this.loading = true;
        this.axios.post(url, obj, config).then(
          (response) => {
            if (response.status === 200) {
              this.x.registerSuccess = true;
              this.loading = false;
              this.mainError = null;
            } else {
              this.x.error = "Failed to register";
              this.loading = false;
              this.mainError = null;
              this.mainError1 = null;
            }
            this.loading = false;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.loading = false;
            this.mainError = null;
            this.mainError1 = null;
            this.x.error = this.$eventBus.parse(error);
          }
        );
      } else {
        this.loading = false;
        this.x.error = "Please fill all required fields in above form! ";
      }
      // }
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },
    profilePicChange(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileUploadProfile.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImageProfile(files[0], 1);
    },
    nationalIDChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileNationalId.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 2);
    },
    passportChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.filePassport.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 3);
    },
    licenseChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileLicense.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 4);
    },

    vehicleRegistrationChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileVehicleReg.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 5);
    },

    vehicleInsuranceChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileInsurance.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 6);
    },
    vehicleAuthLetterChanged(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileAuth.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 7);
    },
    licenseChangedBack(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileLicenseB.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 8);
    },
    vehicleRegistrationChangedBack(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileVehicleRegB.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 9);
    },
    emiratesIdChangedFront(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileEmirates.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 10);
    },
    emiratesIdChangedBack(e) {
      this.cropFileUrl = "";
      var files = e.target.files || e.dataTransfer.files;
      setTimeout(() => {
        this.$refs.fileEmiratesB.value = "";
      }, 4000);
      if (!files.length) return;
      this.createImage(files[0], 11);
    },
    createImageProfile(file, status) {
      this.x.error = null;
      this.errorCropper = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.errorCropper = "Please upload image with size less than 5MB!";
        this.processing = false;

        return false;
      }
      if (
        file.name
          .toString()
          .substr(file.name.toString().lastIndexOf(".") + 1) == "jfif"
      ) {
        this.errorCropper =
          "Please upload image of file type png , jpg or jpeg!";

        this.processing = false;
        return false;
      }

      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg"
      ) {
        this.errorCropper =
          "Please upload image of file type png , jpg or jpeg!";

        this.processing = false;
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.loading = false;

        this.upload(file, status);
      }
    },

    createImage(file, status) {
      this.x.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload image with size less than 5MB!";
        this.processing = false;

        return false;
      }
      if (
        file.name
          .toString()
          .substr(file.name.toString().lastIndexOf(".") + 1) == "jfif"
      ) {
        this.errorCropper =
          "Please upload image of file type png , jpg ,jpeg or pdf!";
        this.processing = false;
        return false;
      }
      if (
        file.name
          .toString()
          .substr(file.name.toString().lastIndexOf(".") + 1) == "jfif"
      ) {
        this.errorCropper =
          "Please upload image of file type png , jpg ,jpeg or pdf!";

        this.processing = false;
        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        //   this.$refs.fileUpload.value = "";

        this.x.error =
          "Please upload image of file type png , jpg ,jpeg or pdf!";

        this.processing = false;
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.loading = false;

        this.upload(file, status);
      }
    },
    upload(file, statusChange) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.x.error = null;
      this.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (statusChange == 1) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.profilePicURL = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.profilePicURL = response.data.data.original;
              }
            } else if (statusChange == 2) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.nationalIdImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.nationalIdImage = response.data.data.original;
              }
            } else if (statusChange == 3) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.passPortImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.passPortImage = response.data.data.original;
              }
            } else if (statusChange == 4) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.licenseImage = response.data.data.original;
              } else {
                //  this.cropperModal = true;
                this.status = statusChange;
                this.licenseImage = response.data.data.original;
              }
            } else if (statusChange == 5) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.vehicleRegImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.vehicleRegImage = response.data.data.original;
              }
            } else if (statusChange == 6) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.vehicleInsuranceImage = response.data.data.original;
              } else {
                //this.cropperModal = true;
                this.status = statusChange;
                this.vehicleInsuranceImage = response.data.data.original;
              }
            } else if (statusChange == 7) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                //this.cropperModal = false;
                this.status = statusChange;
                this.vehicleAuthLetterImage = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.vehicleAuthLetterImage = response.data.data.original;
              }
            } else if (statusChange == 8) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.licenseImageBack = response.data.data.original;
              } else {
                //this.cropperModal = true;
                this.status = statusChange;
                this.licenseImageBack = response.data.data.original;
              }
            } else if (statusChange == 9) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.vehicleRegImageBack = response.data.data.original;
              } else {
                //this.cropperModal = true;
                this.status = statusChange;
                this.vehicleRegImageBack = response.data.data.original;
              }
            } else if (statusChange == 10) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.emiratesIDImageFront = response.data.data.original;
              } else {
                //this.cropperModal = true;
                this.status = statusChange;
                this.emiratesIDImageFront = response.data.data.original;
              }
            } else if (statusChange == 11) {
              if (
                file.type
                  .toString()
                  .substr(file.type.toString().lastIndexOf(".") + 1) ==
                "application/pdf"
              ) {
                // this.cropperModal = false;
                this.status = statusChange;
                this.emiratesIDImageBack = response.data.data.original;
              } else {
                // this.cropperModal = true;
                this.status = statusChange;
                this.emiratesIDImageBack = response.data.data.original;
              }
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.loading = false;
        }
      );
    },
    createCropImage(file, status) {
      this.x.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload image with size less than 5MB!";
        this.processing = false;
        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload image of file type png , jpg ,jpeg or pdf!";
        this.processing = false;
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.loading = false;
        this.uploadCropImage(file, status);
      }
    },
    uploadCropImage(file, statusChange) {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;
          if (statusCode === 200) {
            this.cropFileUrl = "";
            if (statusChange == 1) {
              this.profilePicURL = response.data.data.original;
            } else if (statusChange == 2) {
              this.status == 2;
              this.nationalIdImage = response.data.data.original;
            } else if (statusChange == 3) {
              this.status == 3;
              this.passPortImage = response.data.data.original;
            } else if (statusChange == 4) {
              this.status == 4;
              this.licenseImage = response.data.data.original;
            } else if (statusChange == 5) {
              this.status == 5;
              this.vehicleRegImage = response.data.data.original;
            } else if (statusChange == 6) {
              this.status == 6;
              this.vehicleInsuranceImage = response.data.data.original;
            } else if (statusChange == 7) {
              this.status == 7;
              this.vehicleAuthLetterImage = response.data.data.original;
            } else if (statusChange == 8) {
              this.status == 8;
              this.licenseImageBack = response.data.data.original;
            } else if (statusChange == 9) {
              this.status == 9;
              this.vehicleRegImageBack = response.data.data.original;
            } else if (statusChange == 10) {
              this.status == 10;
              this.emiratesIDImageFront = response.data.data.original;
            } else if (statusChange == 11) {
              this.status == 11;
              this.emiratesIDImageBack = response.data.data.original;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.menu,
.menu__content {
  background: white !important;
}
.divider-rule {
  border: 0;
  height: 2px;
  margin: 30px 0;
  background-color: transparent;
}
.minHeight {
  min-height: 300px;
}
.m-10 {
  margin: 10px;
}
.error-msg {
  position: absolute;
  margin-top: -15px;
  font-size: 12px;
  color: #fb5757;
}
</style>
